import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4 mb-xl-0 d-flex flex-column h-100" }
const _hoisted_2 = { class: "d-none d-sm-block" }
const _hoisted_3 = { class: "nav-bot-wrapper mt-auto" }
const _hoisted_4 = { class: "d-flex justify-content-center mt-2" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsAvatar = _resolveComponent("NewsAvatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Version = _resolveComponent("Version")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: { name: 'Profile' } }, {
        default: _withCtx(() => [
          _createVNode(_component_NewsAvatar)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: require('@/assets/img/logo/drivebuzz_Logo_quer.svg'),
          alt: "Logo",
          class: "my-3",
          style: {"width":"250px"}
        }, null, 8, _hoisted_5)
      ]),
      _createVNode(_component_Version)
    ])
  ]))
}